import recruitBoard from '../../../assets/images/recruit/board.png';
import recruitLeaderboard from '../../../assets/images/recruit/leaderboard.png';

import proLeaderboard from '../../../assets/images/pro/leaderboard.png';
import proPlayer from '../../../assets/images/pro/player.png';
import proTendencies from '../../../assets/images/pro/tendencies.png';
import proViewer from '../../../assets/images/pro/viewer.png';

import collegeLeaderboard from '../../../assets/images/college/leaderboard.png';
import collegeAlignment from '../../../assets/images/college/alignment.png';
import collegeMatchup from '../../../assets/images/college/matchup.png';
import collegePlayer from '../../../assets/images/college/player.png';
import collegeTransferPortal from '../../../assets/images/college/transfer-portal.png';
import collegeViewer from '../../../assets/images/college/viewer.png';

export const CAROUSEL_IMAGES: any = {
    pro: [proViewer, proPlayer, proLeaderboard, proTendencies],
    college: [
        collegeViewer,
        collegeTransferPortal,
        collegePlayer,
        collegeLeaderboard,
        collegeMatchup,
        collegeAlignment,
    ],
    recruit: [recruitBoard, recruitLeaderboard],
};
