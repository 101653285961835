import React, { FunctionComponent, useState, useEffect } from 'react';
import { Button, Input } from 'semantic-ui-react';
import MailchimpSubscribe, { FormHooks } from 'react-mailchimp-subscribe';
import styles from './contact.module.scss';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const MAILCHIMP_URI = process.env.REACT_APP_MAILCHIMP_URI ?? '';
const CustomForm: FunctionComponent<FormHooks<any>> = ({ status, message, subscribe }) => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [team, setTeam] = useState('');
    const [products, setProducts] = useState<string[]>([]);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        email &&
            firstName &&
            lastName &&
            email.indexOf('@') > -1 &&
            subscribe({
                EMAIL: email,
                MERGE1: firstName,
                MERGE2: lastName,
                MERGE3: team,
                MERGE4: products.join(','),
            });
    };

    useEffect(() => {
        if (status === 'success') clearFields();
    }, [status]);

    const options = [
        { value: 'PRO', label: 'Pro' },
        { value: 'COLLEGE', label: 'College' },
        { value: 'RECRUIT', label: 'Recruit' },
        { value: 'BROADCAST', label: 'Broadcast' },
    ];

    const clearFields = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setTeam('');
        setProducts([]);
    };

    return (
        <form className={styles.form}>
            <div>
                <h3 className={styles.header}>CONTACT</h3>
                <hr style={{ height: 3, backgroundColor: 'white', width: 70 }} />
                {status !== 'success' ? <p className={styles.demo}>Interested in a demo?</p> : null}
            </div>
            <h3 className={styles.title}>
                {status === 'success' ? '- We look forward to getting in contact with you -' : null}
            </h3>
            {status === 'sending' && <div className={styles.success}>sending...</div>}
            {status === 'error' && (
                <div className="mc__alert mc__alert--error" dangerouslySetInnerHTML={{ __html: message.toString() }} />
            )}
            {status === 'success' && <div className={styles.success} dangerouslySetInnerHTML={{ __html: message }} />}
            {status !== 'success' ? (
                <>
                    <Input
                        className={styles.input}
                        onChange={(_, { value }) => setFirstName(value)}
                        type="text"
                        value={firstName}
                        placeholder="First Name"
                        isRequired
                    />
                    <Input
                        className={styles.input}
                        onChange={(_, { value }) => setLastName(value)}
                        type="text"
                        value={lastName}
                        placeholder="Last Name"
                        isRequired
                    />
                    <Input
                        className={styles.input}
                        onChange={(_, { value }) => setEmail(value)}
                        type="email"
                        value={email}
                        placeholder="Email Address"
                        isRequired
                    />
                    <Input
                        className={styles.input}
                        onChange={(_, { value }) => setTeam(value)}
                        type="text"
                        value={team}
                        placeholder="Team/Organization"
                        isRequired
                    />
                    <Select
                        options={options}
                        isMulti
                        onChange={(n) => setProducts(n.map((p: any) => p.value))}
                        components={animatedComponents}
                        placeholder="Which products are you interested in?"
                        className="react-select-container"
                        classNamePrefix={'react-select'}
                        isSearchable={false}
                        styles={{
                            singleValue: ({ ...styles }) => ({
                                ...styles,
                                maxWidth: 120,
                            }),
                        }}
                        menuPlacement="top"
                        closeMenuOnSelect={false}
                    />
                </>
            ) : null}
            {status === 'success' ? null : (
                <Button onClick={handleSubmit} className={styles.submit}>
                    Submit
                </Button>
            )}
        </form>
    );
};

const ContactUs: FunctionComponent = () => {
    return (
        <div className={styles.container}>
            <div className="mc__form-container">
                <MailchimpSubscribe
                    url={MAILCHIMP_URI}
                    render={(hooks) => {
                        return <CustomForm {...(hooks as any)} />;
                    }}
                />
            </div>
        </div>
    );
};

export default ContactUs;
