import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Image } from 'semantic-ui-react';
import ReactPlayer from 'react-player/lazy';

import webStyles from './brand.module.scss';
import mobileStyles from './brand-mobile.module.scss';
import bgSrc from '../../../../assets/images/textures/triangle-dark.png';
import useIntersection from 'src/shared/hooks/use-intersection';
import useMobile from 'src/shared/hooks/use-mobile';

const Brand: FunctionComponent = () => {
    const [loaded, setLoaded] = useState(false);
    const [visible, setVisible] = useState(false);
    const [playing, setPlaying] = useState(false);
    const video = useRef<ReactPlayer>(null);
    const container = useRef<HTMLDivElement>(null);
    const videoOnScreen = useIntersection(container, '-300px');

    const mobile = useMobile();
    const styles = mobile ? mobileStyles : webStyles;

    useEffect(() => {
        if (loaded && visible) {
            video.current?.seekTo(4);
            setPlaying(true);
        }
    }, [loaded, visible]);

    useEffect(() => {
        if (videoOnScreen && !visible) setVisible(true);
    }, [videoOnScreen]);

    return (
        <div className={styles.container}>
            <Image src={bgSrc} className={styles.bg} />
            <div className={styles.children}>
                <div className={styles.video} ref={container}>
                    <ReactPlayer
                        url="https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/ts-product.mp4"
                        controls
                        playsinline
                        playing={playing}
                        onReady={() => setLoaded(true)}
                        ref={video}
                        height={'100%'}
                        width={'100%'}
                    />
                </div>
                <div className={styles.pillars}>
                    <div className={styles.pillar}>
                        <h2>trusted.</h2>
                        <p>Sports data technology custom built for success.</p>
                    </div>
                    <div className={styles.pillar}>
                        <h2>efficient.</h2>
                        <p>Your team&apos;s team: streamlining the process.</p>
                    </div>
                    <div className={styles.pillar}>
                        <h2>responsive.</h2>
                        <p>On call: ready to explore and create.</p>
                    </div>
                </div>
                <div className={styles.content}>
                    <p>
                        <span>Changing the way data influences the sports experience.</span> Established as a
                        trusted,respected and responsive leader in the professional sports data technology arena,
                        Telemetry Sports creates custom-built products committed to supporting sports
                        organization&apos;s successful outcomes. We&apos;re providing unique, efficient, and flexible
                        sports data solutions to strengthen everyday team, player, practice and recruitment processes.
                        From professional sports organizations to individual sport savvy consumers, we remain diligent
                        to provide customizable data technology adapted to fit end-user needs.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Brand;
