import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { Button, Menu } from 'semantic-ui-react';
import NAVIGATION_ROUTES from '../config/routes';
import IRoute from '../shared/models/interfaces/iroute';
import Logo from './components/client-logo/client-logo.component';
import NavigationItem from './components/navigation-item/navigation-item.component';
import { useRoutes } from 'react-router-dom';
import Footer from './components/footer/footer.component';
import useMobile from 'src/shared/hooks/use-mobile';
import MobileMenu from './components/mobile-menu/mobile-menu.component';

import ReactGA from 'react-ga4';

interface ApplicationLayoutProps {
    children: JSX.Element | JSX.Element[] | null | any;
}

const ApplicationLayout: FunctionComponent<ApplicationLayoutProps> = (props) => {
    const isMobile = useMobile();
    const route = useRoutes(NAVIGATION_ROUTES);
    const renderNavItems = useCallback(() => {
        const navItems = NAVIGATION_ROUTES.filter((r: IRoute) => r.isNavItem);
        return (
            navItems &&
            navItems.map((n: IRoute, index: number) => {
                return <NavigationItem key={index} route={n} />;
            })
        );
    }, [NAVIGATION_ROUTES]);

    const light = ['about', 'contact'].includes(route?.props.match.route.path);

    useEffect(() => {
        const pathname = route?.props.match.route.path;
        let pageView;
        if (pathname === '*') pageView = '/not-found';
        else pageView = pathname;
        ReactGA.send({ hitType: 'pageview', page: '/' + pageView });
    }, [route]);

    const recordEvent = () => {
        ReactGA.event({
            category: 'Clicks',
            action: 'Contact',
            label: 'header',
        });
    };

    return (
        <div className="app-base">
            {!isMobile ? (
                <div className={!light ? 'nav-container' : 'nav-container-light'}>
                    <div className="limit">
                        <Logo color={!light ? 'white' : 'full'} />
                        <Menu borderless secondary className="navigation" inverted>
                            {renderNavItems()}
                            <a href="/contact">
                                <Button className={!light ? 'contact' : 'contact-light'} onClick={recordEvent}>
                                    CONTACT US
                                </Button>
                            </a>
                        </Menu>
                    </div>
                </div>
            ) : (
                <MobileMenu light={light} />
            )}
            {props.children}
            <Footer />
        </div>
    );
};

export default ApplicationLayout;
