import React, { FunctionComponent } from 'react';
import ContactUs from 'src/shared/components/contact/contact.component';
import VerticalHeader from 'src/shared/components/vertical-header/vertical-header.component';
import { Image } from 'semantic-ui-react';
import webStyles from './recruit.module.scss';
import mobileStyles from './recruit-mobile.module.scss';

import recruitFlow from '../../assets/images/recruit-generic-flow.svg';
import useMobile from 'src/shared/hooks/use-mobile';
import useWindowDimensions from 'src/shared/hooks/use-window-dimensions';
import Carousel from '../../shared/components/carousel/carousel.component';

const Recruit: FunctionComponent = () => {
    const mobile = useMobile();
    const styles = mobile ? mobileStyles : webStyles;
    const { width } = useWindowDimensions();

    return (
        <div className={styles.container}>
            <VerticalHeader title="Recruit" tagline="INSERT RELEVANT RECRUIT TAGLINE/HEADER HERE" />
            <div className={styles.content}>
                <Carousel page="recruit" />
                <div className={styles.descriptionContainer} style={{ width, maxWidth: width }}>
                    <div className={styles.description}>
                        <div className={styles.descriptionText}>
                            <p>
                                <span style={{ fontWeight: 800, color: '#2e9ef2' }}>Your Big Board goes digital. </span>
                                Telemetry Sports Recruit takes your Big Board process digital, allowing personnel to
                                customize player identity, and evaluate player performance. The player evaluation
                                process gets streamlined allowing personnel to efficiently customize player information
                                and evaluate player performance. Truly an efficiency game-changer.
                            </p>
                            <p>
                                <span style={{ fontWeight: 800, color: '#2e9ef2' }}>
                                    Quality data=informed decision making.{' '}
                                </span>
                                With thousands of potential recruits, having the time and resources to manually
                                aggregate data and review film for each player is overwhelming. As a support resource
                                for recruiting personnel, Telemetry Sports Recruit allows for efficient, already curated
                                data to be served up and ready for evaluation, ranking and organization of ideal team
                                recruits.
                            </p>
                        </div>
                        <div className={styles.graphic}>
                            <Image src={recruitFlow} />
                        </div>
                    </div>
                </div>
                <div className={styles.pillars}>
                    <div>
                        <h2>experienced.</h2>
                        <p>
                            Trusted as a responsive sports data technology provider to over 30 NFL and NCAA&reg;
                            organizations, Telemetry Sports now offers scouts a reliable recruitment tool to aid your
                            process of evaluating prospective player personnel.
                        </p>
                    </div>
                    <div>
                        <h2>customizable.</h2>
                        <p>
                            At Telemetry Sports, we understand a team&apos;s unique needs and desires when evaluating
                            and organizing potential recruits. Customizable solutions and client-specific data
                            integrations are our bread-and-butter.
                        </p>
                    </div>
                    <div>
                        <h2>streamlined.</h2>
                        <p>
                            Beyond the perks of using Telemetry Sports&apos; efficient and reliable recruitment tool for
                            individual team scouting personnel, the ability to customize how data is shared, curated,
                            and delivered is dependent on each team&apos;s individual needs.
                        </p>
                    </div>
                </div>
            </div>
            <ContactUs />
        </div>
    );
};

export default Recruit;
