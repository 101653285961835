export interface Employee {
    name: string;
    title: string;
    headshot: string;
}

const employees: Employee[] = [
    {
        name: 'Jeremy Hochstedler',
        title: 'Founder & CEO',
        headshot: 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/headshots/jeremyh.png',
    },
    {
        name: 'Nathan Jahn',
        title: 'Head of Business Development & Strategy',
        headshot: 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/headshots/natej.png',
    },
    {
        name: 'Andrew Rodgers',
        title: 'Head of Product Strategy & Operations',
        headshot: 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/headshots/andrewr.png',
    },
    {
        name: 'Chris Moyer',
        title: 'Head of Product Development',
        headshot: 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/headshots/chrism.png',
    },
    {
        name: 'Jordan Chipka',
        title: 'Head of Research & Development',
        headshot: 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/headshots/jordanc.png',
    },
    {
        name: 'Tyler Fuelling',
        title: 'Data Engineer',
        headshot: 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/headshots/tylerf.png',
    },
    {
        name: 'Amy Hochstedler',
        title: 'Human Resources Business Partner & Operations',
        headshot: 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/headshots/amyh.png',
    },
    {
        name: 'Wyatt Klueber',
        title: 'Full Stack Developer',
        headshot: 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/headshots/wyattk.png',
    },
    {
        name: 'Cody Steiner',
        title: 'Jr. Full Stack Developer',
        headshot: 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/headshots/codys.png',
    },
    {
        name: 'Ben Goldstein',
        title: 'Jr. Full Stack Developer',
        headshot: 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/headshots/beng.png',
    },
    {
        name: 'Clay Troyer',
        title: 'Operations and Product Development',
        headshot: 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/headshots/clayt.png',
    },
];

export default employees;
