import React, { FunctionComponent } from 'react';
import ContactUs from 'src/shared/components/contact/contact.component';
import VerticalHeader from 'src/shared/components/vertical-header/vertical-header.component';
import { Image } from 'semantic-ui-react';
import webStyles from './college.module.scss';
import mobileStyles from './college-mobile.module.scss';
import useMobile from 'src/shared/hooks/use-mobile';
import useWindowDimensions from 'src/shared/hooks/use-window-dimensions';
import { Parallax } from 'react-scroll-parallax';
import playerImg from '../../assets/images/players/sprint.svg';
import Carousel from 'src/shared/components/carousel/carousel.component';

const College: FunctionComponent = () => {
    const mobile = useMobile();
    const styles = mobile ? mobileStyles : webStyles;

    const { width } = useWindowDimensions();

    return (
        <div className={styles.container}>
            <VerticalHeader title="College" tagline="INSERT RELEVANT COLLEGE TAGLINE/HEADER HERE" />
            <div className={styles.content}>
                <Carousel page="college" />
                <div className={styles.descriptionContainer} style={{ width, maxWidth: width }}>
                    <div className={styles.description}>
                        {!mobile ? (
                            <Parallax className={styles.graphic} translateX={[30, 10]} startScroll={0} endScroll={800}>
                                <Image src={playerImg} />
                            </Parallax>
                        ) : null}
                        <div className={styles.descriptionText}>
                            <p>
                                <span style={{ fontWeight: 800, color: '#2e9ef2' }}>
                                    Portable. Moldable. Flexible.{' '}
                                </span>
                                Inspired by each NFL and NCAA&reg; organization&apos;s unique needs and desired
                                outcomes, Telemetry Sports College is dedicated to working tirelessly to stay nimble,
                                agile, and responsive to provide on-demand sports data technology solutions centered
                                around NCAA&reg; player tracking data to seamlessly integrate into your team&apos;s
                                already-in-place processes.
                            </p>
                            <p>
                                <span style={{ fontWeight: 800, color: '#2e9ef2' }}>It&apos;s a game-changer. </span>
                                Beyond quality sports data delivery, our team is committed to the ethical treatment of
                                shared organizational information and data technology building a foundation of feedback
                                and focused deliverables producing winning results.
                            </p>
                        </div>
                        {mobile ? (
                            <Parallax className={styles.graphic} translateX={[0, 0]} startScroll={0} endScroll={800}>
                                <Image src={playerImg} />
                            </Parallax>
                        ) : null}
                    </div>
                </div>
                <div className={styles.pillars}>
                    <div>
                        <h2>unique.</h2>
                        <p>
                            Solutions built for your unique team needs. We&apos;re good listeners and we hear our
                            clients when they share their unique data needs and opportunities to streamline efficiencies
                            to maximize the ability to view data reliably and quickly. Telemetry Sports transitions the
                            active-listening phase with clients into production of on-demand, time-saving customized
                            solutions that will allow professional and collegiate organizations to stay informed,
                            proactive decision-makers.
                        </p>
                    </div>
                    <div>
                        <h2>flexible.</h2>
                        <p>
                            Responsive and creative in our solutions. Our team partners know that while much of what
                            Telemetry Sports provides through sports data technology can come down to numbers, what we
                            really thrive on is exploring new solutions to ever-evolving team pain points and needs. Our
                            product is flexible and responsive to provide end-users options.
                        </p>
                    </div>
                    <div>
                        <h2>streamlined.</h2>
                        <p>
                            We become an extension of your process. The layers of preparation and planning that go into
                            a successful and winning collegiate organization&apos;s season can be overwhelming, time
                            consuming and relentless. We are diligent in providing trusted, quality sports data
                            technology to efficiently connect organizations with the information they need in real-time
                            to make informed and reliable player, game, and season decisions.
                        </p>
                    </div>
                </div>
            </div>
            <ContactUs />
        </div>
    );
};

export default College;
