import React, { FunctionComponent } from 'react';
import { Image } from 'semantic-ui-react';
import useMobile from 'src/shared/hooks/use-mobile';
import employees, { Employee } from './employees';
import webStyles from './team.module.scss';
import mobileStyles from './team-mobile.module.scss';

const Team: FunctionComponent = () => {
    const mobile = useMobile();
    const styles = mobile ? mobileStyles : webStyles;

    const person = (person: Employee) => {
        return (
            <div key={person.name} className={styles.person}>
                <Image src={person.headshot} />
                <h3>{person.name}</h3>
                <h4>{person.title}</h4>
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <h2>OUR TEAM</h2>
            <div className={styles.team}>
                {mobile ? (
                    <>
                        <div className={styles.row} style={{ maxWidth: 900 }}>
                            {employees.slice(0, 2).map(person)}
                        </div>
                        <div className={styles.row} style={{ maxWidth: 900 }}>
                            {employees.slice(2, 4).map(person)}
                        </div>
                        <div className={styles.row} style={{ maxWidth: 900 }}>
                            {employees.slice(4, 6).map(person)}
                        </div>
                        <div className={styles.row} style={{ maxWidth: 900 }}>
                            {employees.slice(6, 8).map(person)}
                        </div>
                        <div className={styles.row} style={{ maxWidth: 900 }}>
                            {employees.slice(8, 10).map(person)}
                        </div>
                        <div className={styles.row} style={{ maxWidth: 900 }}>
                            {employees.slice(10).map(person)}
                            <div className={styles.person} />
                        </div>
                    </>
                ) : (
                    <>
                        <div className={styles.row} style={{ maxWidth: 900 }}>
                            {employees.slice(0, 5).map(person)}
                        </div>
                        <div className={styles.row}>{employees.slice(5).map(person)}</div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Team;
