import React from 'react';
import About from 'src/features/about/about.component';
import Broadcast from 'src/features/broadcast/broadcast.component';
import College from 'src/features/college/college.component';
import Contact from 'src/features/contact/contact.component';
import Pro from 'src/features/pro/pro.component';
import Recruit from 'src/features/recruit/recruit.component';
import Home from '../features/home/home.component';
import IRoute from '../shared/models/interfaces/iroute';

const NAVIGATION_ROUTES: IRoute[] = [
    {
        path: '',
        element: <Home />,
        name: 'Home',
    },
    {
        path: 'about',
        element: <About />,
        name: 'About',
        isNavItem: true,
    },
    {
        path: 'pro',
        element: <Pro />,
        name: 'Pro',
        isNavItem: true,
    },
    {
        path: 'college',
        element: <College />,
        name: 'College',
        isNavItem: true,
    },
    {
        path: 'recruit',
        element: <Recruit />,
        name: 'Recruit',
        isNavItem: true,
    },
    {
        path: 'broadcast',
        element: <Broadcast />,
        name: 'Broadcast',
        isNavItem: true,
    },
    {
        path: 'contact',
        element: <Contact />,
        name: 'Contact',
        isNavItem: false,
    },
];

export default NAVIGATION_ROUTES;
