import React, { FunctionComponent, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import { Icon, Image } from 'semantic-ui-react';

import useMobile from 'src/shared/hooks/use-mobile';
import webStyles from './carousel.module.scss';
import mobileStyles from './carousel-mobile.module.scss';

import { CAROUSEL_IMAGES } from './images';

const Carousel: FunctionComponent<{ page: 'pro' | 'college' | 'recruit' }> = ({ page }) => {
    const mobile = useMobile();
    const styles = mobile ? mobileStyles : webStyles;

    const PrevArrow: FunctionComponent = (props: any) => {
        const { onClick } = props;
        return <Icon name="chevron circle left" className={styles.prevArrow} onClick={onClick} />;
    };

    const NextArrow: FunctionComponent = (props: any) => {
        const { onClick } = props;
        return <Icon name="chevron circle right" className={styles.nextArrow} onClick={onClick} />;
    };

    const [activeSlide, setActiveSlide] = useState(0);

    const settings: Settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        className: styles.container,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        customPaging: (i) => {
            if (i === activeSlide) {
                return <Icon name="circle" style={{ color: '#2e9ef2', fontSize: '1rem' }} />;
            }
            return <Icon name="circle" color="grey" style={{ fontSize: '.8rem' }} />;
        },
        afterChange: setActiveSlide,
    };

    const images: any[] = CAROUSEL_IMAGES[page];

    return (
        <Slider {...settings}>
            {images.map((img: any, i) => {
                return <Image src={img} key={i} />;
            })}
        </Slider>
    );
};

export default Carousel;
